<template>
    <div class="letsPRINTtxt">
        <div class="txt">
            <p class="title">LET'S PRINT!</p>
            <p class='p1' lang="nl">Bij RISOOM kan je terecht om RISO te drukken in de beschikbare kleuren en het beschikbare papier. Er zijn 3 formules:
                <ul>
                    <li><div><p><b>just print:</b> Gewoon printen dus. Jij bereidt de bestanden zelf voor [zie <i>fileprep</i>] en wij printen. Geen zorgen als dat niet meteen lukt. Zeker de eerste keren zal dit wat zoeken zijn. We beantwoorden graag vragen en helpen waar nodig, we willen tenslotte graag dat je voor RISO kiest.</p></div></li>
                    <li><div><p><b>print with prepress:</b> Je levert je bestanden aan zoals je het hebt gemaakt en laat de omzetting naar RISO printbare bestanden over aan ons. Je laat de controle dus los, eenvoudiger, misschien wat spannender maar met alle vertrouwen.</p></div></li>
                    <li><div><p><b>print together:</b> We spreken een moment af en we zetten de bestanden samen om zodat ze door de RISO machine begrepen worden. We bekijken samen de resultaten en gaan ofwel meteen over tot productie, ofwel gebeurt de afwerking later, afhankelijk van de nodige droogtijden.</p></div></li>
                </ul>
            </p>
        </div>         

       
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'letsPRINTtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .letsPRINTtxt {
        background-color:#ff747765;
    }

    .letsPRINTtxt .txt {

    }    

    .letsPRINTtxt p.title{
        position: absolute;
        top: -50px;
        right: -40px;    
    }    


    @media only screen and (max-width: 1279px) {
        .letsPRINTtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


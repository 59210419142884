<template>
    <div class="filePREPtxt">
        <div class="txt">
            <p class="title">FILE PREP</p>
            <p class='p1' lang="nl"> De basis om te onthouden bij maken van je RISO project is:
                <ul>
                    <li><div><p>De machine print 1 kleur per keer. </p></div></li>
                    <li><div><p>Het print kleur in grijswaarden. Hoe zwarter, hoe donkerder de kleur.</p></div></li>
                    <li><div><p>De kleuren zijn niet dekkend en mengen op papier, zo worden mengkleuren gecreeërd. Daarnaast worden kleuren ook visueel gemengd door het variëren van screens.</p></div></li>
                </ul>
            </p>
            <p class='p1' lang="nl"> Grootte:
                <ul>
                    <li><div><p>Wij printen voornamelijk in A3 papier. Alle andere formaten moeten worden versneden. Voor ons is het leuk als de zetting al is gebeurd op een A3 formaat. </p></div></li>
                    <li><div><p>De machine print niet randloos. Je moet rekening houden met 1cm marge op alle randen van een A3 papier. Het grootste randloze formaat is dus 27.7cm op 40 cm groot.</p></div></li>                   
                    <li><div><p>Om zeker te zijn dat het randloos is, kan je in je ontwerp best minimaal 3mm over die marges gaan [zogenaamde <i>bleed</i>]. Indien je crop marks plaats kan je deze ook best 3mm buiten je ontwerp plaatsen (we houden daar dan rekening mee), of beperk je deze best tot 1 kleurlaag.</p></div></li>
                    <li><div><p>Het sticker papier is in A4. De zetting doen we zelf. Je kan dus een bestand opleveren van 38mm op 38mm.</p></div></li>
                </ul>
            </p>
            <p class='p1' lang="nl"> Andere tips:
                <ul>
                    <li><div><p>Het is belangrijk om in je bestanden lagen te vermijden. De machine kan deze al eens verkeerd interpreteren [<i>ghosting</i>]. Gebruik dus altijd <i>flatten layers</i> voor het exporteren en vermijd laag effecten. Gebruik dus <i>overprint</i> in plaats van <i>multiply</i>.</p></div></li>
                    <li><div><p>Tekst moet altijd als <i>type</i> worden geëxporteerd en niet als raster of vector. Dat betekent dat tekst pas toegevoegd mag worden worden na het exporteren van een raster.</p></div></li>
                    <li><div><p>Indien je machinesporen wil vermijden, probeer dan in je ontwerp zoveel als mogelijk zones met deze sporen [zie IMPERFECTIES] te vermijden of het inktpercentage te beperken tot maximaal 85%. In de <i>under- & overinking</i> zones geldt hetzelfde. Het beperken van het totaal aantal kleurlagen in het volledige project kan ook helpen.</p></div></li>
                </ul>
            </p>
        </div>
      

        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>


    </div>    
</template>

<script>
    export default {
        name: 'filePREPtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .filePREPtxt {
        background-color:#ff747765;
     
    }

    .filePREPtxt .txt {
    
    }    




    .filePREPtxt p.title{
        position: absolute;
        top: -50px;
        right: 20px; 
  
    }    


    @media only screen and (max-width: 1279px) {

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="examplesIMG3">

            <div class="XMPLs">

                <img src="images/examples/examplesRISOOM01.png"/>
                <div class="caption">
                    <span><i>waves hitting rocks at the beach</i> <br> by RISOOM in <font color="#262626">&#x25B2;</font><font color="#007679">&#x25B2;</font><font color="#ff7477">&#x25B2;</font> on Biotop 120 gsm</span>
                </div>
            

            </div>



    </div>    
</template>

<script>
    export default {
        name: 'examplesIMG3',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .examplesIMG3 {


    }

    .examplesIMG3 .txt {

    }    
    
    .XMPLs {
        align-items: flex-end;
        display: flex;
      
    }

    .XMPLs img{
        width: 70.7%;

    }
    
    .XMPLs .caption {
        bottom:10px;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: right;   
        font-size: calc(14px + 0.4vw);
        writing-mode: vertical-rl;

    }
    span{
        background-color: #fffdee;
    }  
   

    @media only screen and (max-width: 1650px) {
        .XMPLs {


}  

} 
      

</style>


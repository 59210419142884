<template>
    <div class="filePREPtxt">
        <div class="txt">
            <p class="title">FILE PREP</p>
            <p class='p1' lang="en">When preparing your RISO project, remember these basics:
                <ul>
                    <li><div><p>One colour is printed at a time. </p></div></li>
                    <li><div><p>Colour is printed in grayscale: the more black, the darker the colour.</p></div></li>
                    <li><div><p>Colours are not opaque and blend on paper to create mixed colours. Colours are also visually blended by varying in screens.</p></div></li>
                </ul>
            </p>
            <p class='p1' lang="en"> Size:
                <ul>
                    <li><div><p>We primarily print on A3 paper. All other formats require cutting. It helps if the design is already set in A3 format. </p></div></li>
                    <li><div><p>The printer cannot print without borders. Take into account about 1cm margin on all borders of an A3 paper. Your borderless size when printing A3 will be 27.7 x 40cm. To be certain your end result is borderless, it is better to have your design bleed 3mm into the margins. if you add crop marks, put them either 3mm outside your design (we'll keep this in mind) or limit them to one color. </p></div></li>
                    <li><div><p>The sticker paper is A4. We do the layout ourselves. You can deliver a file in 38mmx38mm.</p></div></li>
                </ul>
            </p>
            <p class='p1' lang="en"> Other tips:
                <ul>
                    <li><div><p>It is important to avoid using layers in your files. These can be misinterpreted by the machine into so called ghosting effects. Always use flatten layers when exporting and avoid layer effects, so use overprint instead of multiply.</p></div></li>
                    <li><div><p>Text should always be exported as a type object and not as raster or vector. This means that text can only be added after exporting a raster file. Important to know if you want to combine raster and text.</p></div></li>                    
                    <li><div><p>To avoid marks by the machine try to completely avoid these zones in your design, or at least try to limit the ink percentage there to a maximum of 85%. The same goes for the under- & overinking zones.</p></div></li>
                  
                </ul>
            </p>
        </div>        

        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>


    </div>    
</template>

<script>
    export default {
        name: 'filePREPtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .filePREPtxt {
        background-color:#ff747765;
     
    }

    .filePREPtxt .txt {
    
    }    




    .filePREPtxt p.title{
        position: absolute;
        top: -50px;
        right: 20px; 
  
    }    


    @media only screen and (max-width: 1279px) {

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


import { render, staticRenderFns } from "./overRISOtxt.vue?vue&type=template&id=db00b874&scoped=true&"
import script from "./overRISOtxt.vue?vue&type=script&lang=js&"
export * from "./overRISOtxt.vue?vue&type=script&lang=js&"
import style0 from "./overRISOtxt.vue?vue&type=style&index=0&id=db00b874&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db00b874",
  null
  
)

export default component.exports
<template>
    <div class="extraIMG1">
        <img src="images/rhizomeInPixelArt01.png" />
    </div>
</template>

<script>
    export default {    
            name: 'extraIMG1.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .extraIMG1{
            margin: 0 auto;
            width:0%;
            transform: rotate(5deg);


        }       
        
    }
    
    @media only screen and (min-width: 1280px) {

        .extraIMG1{
            margin: 0 auto;
            width:80%;
            transform: rotate(5deg);

        }

    } 
      

    @media only screen and (min-width: 1850px) {

        .extraIMG1{

            margin: 0 auto;
            width:80%;
            transform: rotate(5deg);            

        }

    }    

</style>


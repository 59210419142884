<template>
    <div class="priceCalculator">
        <div class="txt">     
            <p class="title">PRICE CALC</p>
            <iframe src="https://app.calconic.com/api/embed/calculator/6515d29ea6b143001e1bf868" sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups" title="Calconic_ Calculator" name="Calconic_ Calculator" height="849px" scrolling="no" style="width: 100%; border: 0; outline: none;"></iframe>
    </div>
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>


    </div>    
</template>

<script>
    export default {
        name: 'priceCalculator',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .priceCalculator {
        background-color:#ff747765;
    }
    
    .priceCalculator p.title{
        position: absolute;

        top: -50px;
        right: -25px;     

    }    
    
    #clcltr{
        padding-top: 50px;


    }

    @media only screen and (min-width: 1280px) {
        .priceCalculator {


        }   

    } 
      

    @media only screen and (min-width: 1824px) {


    }  
</style>


<template>
    <div class="overIMPERFECTIESimg4">
        <img src="images/marks05.png" />
    </div>
</template>

<script>
    export default {    
            name: 'overIMPERFECTIESimg4.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .overIMPERFECTIESimg4{
            margin-right: -20vw;
            margin-left: 0vw;
            margin-top: 5vh;


        }       
        
    }
    
    @media only screen and (min-width: 1280px) {

        .overIMPERFECTIESimg4{


        }

    } 
      

    @media only screen and (min-width: 1780px) {

        .overIMPERFECTIESimg4{
            margin-right: -5vw;
            margin-left: -5vw;
            margin-top: 2.5vh;
            transform-origin: 50 50;
            transform: rotate(90deg);
        }

    }    

</style>


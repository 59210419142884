<template>
    <div class="letsPRINTtxt">
        <div class="txt">
            <p class="title">LET'S PRINT!</p>
            <p class='p1' lang="nl">At RISOOM you can print in RISO in the available colour range and available paper range, in three formulas:
                <ul>
                    <li><div><p><b>just print:</b> literally, just print. You prepare the files yourself [see: <i>fileprep</i>] and we do the printing. Don’t worry if you don't to manage it at first: it always takes a little trial and error. We will happily answer any questions and help where needed. After all, we’d like you to choose RISO over other printing techniques.</p></div></li>
                    <li><div><p><b>print with prepress:</b> you deliver the files as made and leave the translation into RISO printable files to us. This means you’re giving a way a bit of control: more simple for you, and perhaps a bit more scary, but you can trust us ;).</p></div></li>
                    <li><div><p><b>print together:</b> we meet and translate your files into RISO printable files together. We go over the results together and either move onto production immediately, or we can handle the finishing later depending on the necessary drying time.</p></div></li>
                </ul>
            </p>
        </div>
       
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'letsPRINTtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .letsPRINTtxt {
        background-color:#ff747765;
    }

    .letsPRINTtxt .txt {

    }    

    .letsPRINTtxt p.title{
        position: absolute;
        top: -50px;
        right: -40px;    
    }    


    @media only screen and (max-width: 1279px) {
        .letsPRINTtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


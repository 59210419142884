import Vue from 'vue';
import App from './App.vue';
import VueScrollTo from 'vue-scrollto';
import DropdownMenu from 'v-dropdown-menu';
import { VLazyImagePlugin } from "v-lazy-image";
import isotope from 'vueisotope';
import imagesLoaded from 'vue-images-loaded';
import 'v-dropdown-menu/dist/v-dropdown-menu.css'; 
import HelloWorld from './components/HelloWorld.vue';
import overKLEURENimg1 from './components/overKLEURENimg1.vue';
import logoRISOOMimg from './components/logoRISOOMimg.vue'
import ENlogoRISOOM from './components/ENlogoRISOOM.vue';
import overIMPERFECTIESimg1 from './components/overIMPERFECTIESimg1.vue';
import overIMPERFECTIESimg2 from './components/overIMPERFECTIESimg2.vue';
import overIMPERFECTIESimg3 from './components/overIMPERFECTIESimg3.vue';
import overIMPERFECTIESimg4 from './components/overIMPERFECTIESimg4.vue';
import overRISOtxt from './components/overRISOtxt.vue';
import ENoverRISOtxt from './components/ENoverRISOtxt.vue';
import overKLEURENtxt from './components/overKLEURENtxt.vue';
import ENoverKLEURENtxt from './components/ENoverKLEURENtxt.vue';
import overKLEURENimg2 from './components/overKLEURENimg2.vue';
import overIMPERFECTIEStxt from './components/overIMPERFECTIEStxt.vue';
import ENoverIMPERFECTIEStxt from './components/ENoverIMPERFECTIEStxt.vue';
import overPAPIERtxt from './components/overPAPIERtxt.vue';
import ENoverPAPIERtxt from './components/ENoverPAPIERtxt.vue';
import overSCREENStxt from './components/overSCREENStxt.vue';
import ENoverSCREENStxt from './components/ENoverSCREENStxt.vue';
import overSCREENSimg from './components/overSCREENSimg.vue';
import logoRISOOM from './components/logoRISOOM.vue';
import priceCalculator from './components/priceCalculator.vue';
import letsPRINTtxt from './components/letsPRINTtxt.vue';
import ENletsPRINTtxt from './components/ENletsPRINTtxt.vue';
import CNTCTtxt from './components/CNTCTtxt.vue';
import enCNTCTtxt from './components/enCNTCTtxt.vue';
import letsWRKSHPtxt from './components/letsWRKSHPtxt.vue';
import ENletsWRKSHPtxt from './components/ENletsWRKSHPtxt.vue';
import filePREPtxt from './components/filePREPtxt.vue';
import ENfilePREPtxt from './components/ENfilePREPtxt.vue';
import fileTYPEStxt from './components/fileTYPEStxt.vue';
import ENfileTYPEStxt from './components/ENfileTYPEStxt.vue';
import extraIMG1 from './components/extraIMG1.vue';
import extraIMG2 from './components/extraIMG2.vue';
import examplesIMG1 from './components/examplesIMG1.vue';
import examplesTitle from './components/examplesTitle.vue';
import examplesIMG2 from './components/examplesIMG2.vue';
import examplesIMG3 from './components/examplesIMG3.vue';
import examplesIMG4 from './components/examplesIMG4.vue';
import risoLayersIMG from './components/risoLayersIMG.vue';
import examplesIMG5 from './components/examplesIMG5.vue';
import examplesIMG6 from './components/examplesIMG6.vue';


Vue.config.productionTip = false;

Vue.use(VueScrollTo);
Vue.use(DropdownMenu) 
Vue.use(VLazyImagePlugin);


Vue.component('HelloWorld', HelloWorld);
Vue.component('overKLEURENimg1', overKLEURENimg1);
Vue.component('logoRISOOMimg', logoRISOOMimg);
Vue.component('ENlogoRISOOM', ENlogoRISOOM);
Vue.component('overIMPERFECTIESimg1', overIMPERFECTIESimg1);
Vue.component('overIMPERFECTIESimg2', overIMPERFECTIESimg2);
Vue.component('overIMPERFECTIESimg3', overIMPERFECTIESimg3);
Vue.component('overIMPERFECTIESimg4', overIMPERFECTIESimg4);
Vue.component('overIMPERFECTIEStxt', overIMPERFECTIEStxt);
Vue.component('ENoverIMPERFECTIEStxt', ENoverIMPERFECTIEStxt);
Vue.component('overKLEURENimg2', overKLEURENimg2);
Vue.component('overKLEURENtxt', overKLEURENtxt);
Vue.component('ENoverKLEURENtxt', ENoverKLEURENtxt);
Vue.component('overRISOtxt', overRISOtxt);
Vue.component('ENoverRISOtxt', ENoverRISOtxt);
Vue.component('overPAPIERtxt', overPAPIERtxt);
Vue.component('ENoverPAPIERtxt', ENoverPAPIERtxt);
Vue.component('overSCREENStxt', overSCREENStxt);
Vue.component('ENoverSCREENStxt', ENoverSCREENStxt);
Vue.component('overSCREENSimg', overSCREENSimg);
Vue.component('logoRISOOM', logoRISOOM);
Vue.component('letsPRINTtxt', letsPRINTtxt);
Vue.component('ENletsPRINTtxt', ENletsPRINTtxt);
Vue.component('CNTCTtxt', CNTCTtxt);
Vue.component('enCNTCTtxt', enCNTCTtxt);
Vue.component('letsWRKSHPtxt', letsWRKSHPtxt);
Vue.component('ENletsWRKSHPtxt', ENletsWRKSHPtxt);
Vue.component('filePREPtxt', filePREPtxt);
Vue.component('ENfilePREPtxt', ENfilePREPtxt);
Vue.component('fileTYPEStxt', fileTYPEStxt);
Vue.component('ENfileTYPEStxt', ENfileTYPEStxt);
Vue.component('priceCalculator', priceCalculator);
Vue.component('risoLayersIMG', risoLayersIMG);
Vue.component('extraIMG1', extraIMG1);
Vue.component('extraIMG2', extraIMG2);
Vue.component('examplesIMG1', examplesIMG1);
Vue.component('examplesTitle', examplesTitle);
Vue.component('examplesIMG2', examplesIMG2);
Vue.component('examplesIMG3', examplesIMG3);
Vue.component('examplesIMG4', examplesIMG4);
Vue.component('examplesIMG5', examplesIMG5);
Vue.component('examplesIMG6', examplesIMG6);




var app = new Vue({
    render: h => h(App),
}).$mount('#app')

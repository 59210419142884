<template>
    <div class="overIMPERFECTIEStxt">
        <div class="txt">
            <p class="title">IMPERFECTIONS</p>
            <p class='p1' lang="nl">When RISO printing you have to take certain things into account:
                <ul>
                    <li><div><p>The ink never fully dries: just like with newspapers some ink will rub off on your fingers</p></div></li>
                    <li><div><p>Handling the printed work results in a little smudging. No matter how careful we are, we cannot avoid this entirely. Don’t stress about this and just embrace it: let it go!</p></div></li>
                    <li><div><p>Other than the smudging, the printer will also leave some traces [so-called roller & needle marks]. And, there are zones on the paper where a little too much or too little ink will be left [over- and underinking]. These <i>mistakes</i> are usually a consequence of printing too dark [>85%] in that particular zone. By cleverly taking this into account in your design, these mistakes can be mostly avoided.</p></div></li>
                    <li><div><p>Each colour is printed separately. This means every colour needs to be aligned manually to the previous one. You should always expect some slack: the colour registration is perfectly imperfect!</p></div></li>
                </ul>
            </p>

        </div>
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'overIMPERFECTIEStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overIMPERFECTIEStxt {
        background-color:#ff747765;
    }

    .overIMPERFECTIEStxt .txt {

    }    

    .overIMPERFECTIEStxt p.title{
        position: absolute;
        top: -60px;
        left: 20px;    
    }    


    @media only screen and (min-width: 0px) {
        .overRISOtxt1 p.title{

        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


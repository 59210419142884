<template>
    <div class="overIMPERFECTIEStxt">
        <div class="txt">
            <p class="title">IMPERFECTIES</p>

            <p class='p1' lang="nl">Als je print met RISO moet je rekening houden met:
                <ul>
                    <li><div><p>Inkt die nooit volledig opdroogt, net zoals bij een krant blijft deze achter op je vingers.</p></div></li>
                    <li><div><p>Dat resulteert in vegen door het behandelen van het drukwerk met de handen. Hoe voorzichtig we ook zijn, het is veelal onmogelijk dit helemaal uit te sluiten. Dit omarmen is veel leuker want minder stresserend: let it go!</p></div></li>
                    <li><div><p>Naast deze vegen, maakt de machine zelf ook sporen [zogenaamde <i>roller & needle marks</i>]. En er zijn zones op het papier waar de machine te veel of te weinig inkt achterlaat [<i>under- & overinking</i>]. Deze 'foutjes' zijn meestal het gevolg van te veel dekking [>85%] in die specifieke zones. Door hier slim rekening mee te houden bij het opmaken van je ontwerp kunnen ze veelal beperkt worden.</p></div></li>
                    <li><div><p>Elke kleur wordt apart geprint. Dat betekent dat het uitlijnen van elke kleur manueel moet gebeuren. Er zal dus altijd wat speling op zitten: de registratie van de kleuren is dus perfect imperfect!</p></div></li>
                </ul>
            </p>
        </div>
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'overIMPERFECTIEStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overIMPERFECTIEStxt {
        background-color:#ff747765;
    }

    .overIMPERFECTIEStxt .txt {

    }    

    .overIMPERFECTIEStxt p.title{
        position: absolute;
        top: -60px;
        left: 20px;    
    }    


    @media only screen and (min-width: 0px) {
        .overRISOtxt1 p.title{

        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="overIMPERFECTIESimg2">


        <div class="txt">
                <img id= "marks03" src="images/marks03.png" />
                <img src="images/marks04.png" />
        </div>
        <div class="CLRyllw"> </div>        
        <div class="CLRbg"> </div>

    </div>
</template>

<script>
    export default {    
            name: 'overIMPERFECTIESimg2.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .overIMPERFECTIESimg2{
            background-color:#ff747765; 
        }       

        .overIMPERFECTIESimg2 img{
            width:50%;
            margin: 0 auto;
            margin-block-end: 35px;
        }
        #marks03{
            width:42.5%;
        }
        
 

    }
    
    @media only screen and (min-width: 1280px) {
        .overIMPERFECTIESimg2 img{
            width:75%;

        }
        #marks03{
            width:68.5%;
        }


    } 
      

    @media only screen and (min-width: 1850px) {

        .overIMPERFECTIESimg2{


        }

    }    

</style>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",attrs:{"id":"app"}},[_vm._m(0),_c('div',{staticClass:"mainbuttons"},[_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ 
                el: '#app', offset: -10 }),expression:"{ \n                el: '#app', offset: -10 }"}],staticClass:"button",class:[_vm.filterM === 'contact' ? 'is-checked' : '']},[_c('span',{on:{"click":function($event){return _vm.mfilter(7)}}},[_vm._v("CONTACT")])]),_c('div',{staticClass:"button"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.filterL)},on:{"click":function($event){return _vm.lfilter()}}})]),_vm._m(1),_vm._m(2),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ 
                el: '#app', offset: -10 }),expression:"{ \n                el: '#app', offset: -10 }"}],staticClass:"button",class:[_vm.filterM === 'FILEPREP' ? 'is-checked' : '']},[_c('span',{on:{"click":function($event){return _vm.mfilter(6)}}},[_vm._v("FILE PREP")])]),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ 
                el: '#app', offset: -10 }),expression:"{ \n                el: '#app', offset: -10 }"}],staticClass:"button",class:[_vm.filterM === 'LETSPRINT' ? 'is-checked' : '']},[_c('span',{on:{"click":function($event){return _vm.mfilter(458)}}},[_vm._v("LET'S PRINT")])]),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#app', offset: -10 }),expression:"{ el: '#app', offset: -10 }"}],staticClass:"button",class:[_vm.filterM === 'RISO' ? 'is-checked' : '']},[_c('span',{on:{"click":function($event){return _vm.mfilter(123)}}},[_vm._v("RISO?")])]),_c('div',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#app', offset: -10 }),expression:"{ el: '#app', offset: -10 }"}],staticClass:"button",class:[_vm.filterM === 'EXAMPLES' ? 'is-checked' : ''],attrs:{"id":"XMPLS"}},[_c('span',{on:{"click":function($event){return _vm.mfilter(8)}}},[_vm._v("EXAMPLES")])])]),_c('div',{staticClass:"content-grid"},[_c('isotope',{directives:[{name:"images-loaded",rawName:"v-images-loaded.on.progress",value:(_vm.layout),expression:"layout",modifiers:{"on":true,"progress":true}}],ref:"cpt",attrs:{"id":"isotope","item-selector":'grid-item',"list":_vm.list,"options":_vm.getOptions()},on:{"filter":function($event){_vm.filterOption=arguments[0]},"sort":function($event){_vm.sortOption=arguments[0]}}},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:item.colWdth},[_c('div',{staticClass:"grid-sizer"}),_c('p',{staticClass:"content"},[_c('v-runtime-template',{attrs:{"template":item.content,"parent":_vm.parentComponent}})],1)])}),0)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader",attrs:{"id":"loader"}},[_vm._v(" loading "),_c('div',{attrs:{"id":"dots"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button",attrs:{"id":"middlebutton"}},[_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button"},[_c('a',{attrs:{"href":"https://shop.risoom.be"}},[_vm._v("SHOP")])])
}]

export { render, staticRenderFns }
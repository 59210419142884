<template>
    <div class="overRISOtxt1">

        
        <div class="txt">

            <p class="title">WAT IS RISO?</p>            

            <p class='p1' lang="nl">RISO is een printproces dat het beste van digitale en analoge  print combineert: de snelheid van een kopieermachine en het handgemaakte gevoel van een zeefdruk.</p> 

            <p class='p1' lang="nl">De machine creeërt intern een stencil dat op een met inkt gevulde cilinder wordt bevestigd. Bij een afdruk roteert de cilinder op hoge snelheid waardoor de inkt door de stencil op het papier wordt geduwd en geabsorbeerd. Zo wordt er relatief weinig energie verbruikt omdat er geen hitte aan te pas komt.</p>
            
            <p class='p1' lang="nl">Bovendien wordt de inkt voornamelijk gemaakt van een restproduct, namelijk rijstzemelen. Allebei beter voor het milieu dus!</p>

        </div>
      
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>

    </div>         

</template>

<script>
export default {
        name: 'risoomTXT',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overRISOtxt1 {
        background-color:#ff747765; 
    }

    .overRISOtxt1 p.title{
        position: absolute;
        top: -50px;
        left: -10px;
    }    


    
    @media only screen and (min-width: 1440px) {
        .overRISOtxt1 p.title{
            left: 350px;
    }    

    } 
      

    @media only screen and (min-width: 1760px) {
        .overRISOtxt1 p.title{
            left: -10px;
    }    

    }  
</style>


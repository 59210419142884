<template>
    <div class="logoRISOOMing">
        <img src="images/drawingRISOduplicator.png"/>
    </div>
</template>

<script>
    export default {    
            name: 'logoRISOOMing.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .logoRISOOMing{
            width: 300%;
            margin-top:-100px;
            margin-bottom:-200px;
            margin-left: -25%;
            padding:0;

        }       
        
    }
    
    @media only screen and (min-width: 1280px) {

        .logoRISOOMing{


        }

    } 
      

    @media only screen and (min-width: 1850px) {

        .logoRISOOMing{
 

        }

    }    

</style>


<template>
    <div class="overIMPERFECTIESimg1">


        <div class="txt">
                <img src="images/marks01.png" />
                <img src="images/marks02.png" />
        </div>
        <div class="CLRyllw"> </div>        
        <div class="CLRbg"> </div>

    </div>
</template>

<script>
    export default {    
            name: 'overIMPERFECTIESimg1.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .overIMPERFECTIESimg1{
            background-color:#ff747765;          
        }       

        .overIMPERFECTIESimg1 img{
            width:50%;
            margin: 0 auto;
            margin-block-end: 25px;

        }
   
 

    }
    
    @media only screen and (min-width: 1280px) {

        .overIMPERFECTIESimg1 img{
            width:75%;



        }

    } 
      

    @media only screen and (min-width: 1850px) {

        .overIMPERFECTIESimg1{


        }

    }    

</style>


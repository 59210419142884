<template>
    <div class="overRISOtxt1">

        <div class="txt">
            <p class="title">WHAT IS RISO?</p>    
            <p class='p1' lang="nl">RISO is a printin process combining the best of digital and analog printing: the speed of a copy machine and the handmade feeling of screen printing.</p> 

            <p class='p1' lang="nl">The printer creates a stencil which is internally attached to a cylinder filled with ink: a drum. When creating a print, the cylinder rotates at high speed, pushing the ink through the stencil onto the paper where it is absorbed. Because there is no heat involved, the process operates at a relatively low energy level.  </p>
            
            <p class='p1' lang="nl">On top of that the ink is primarily based on a waste or side stream: rice bran. All the better for the environment!</p>

        </div>          
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>

    </div>         

</template>

<script>
export default {
        name: 'risoomTXT',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overRISOtxt1 {
        background-color:#ff747765; 
    }

    .overRISOtxt1 p.title{
        position: absolute;
        top: -50px;
        left: -10px;
    }    


    
    @media only screen and (min-width: 1440px) {
        .overRISOtxt1 p.title{
            left: 350px;
    }    

    } 
      

    @media only screen and (min-width: 1760px) {
        .overRISOtxt1 p.title{
            left: -10px;
    }    

    }  
</style>


import { render, staticRenderFns } from "./ENoverKLEURENtxt.vue?vue&type=template&id=f2968910&scoped=true&"
import script from "./ENoverKLEURENtxt.vue?vue&type=script&lang=js&"
export * from "./ENoverKLEURENtxt.vue?vue&type=script&lang=js&"
import style0 from "./ENoverKLEURENtxt.vue?vue&type=style&index=0&id=f2968910&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2968910",
  null
  
)

export default component.exports
<template>
    <div class="overKLEURENimg1">
        <img src="images/colorsWWW.png"/>
    </div>
</template>

<script>
    export default {    
            name: 'overKLEURENimg1.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .overKLEURENimg1{
            padding:0;
            margin: -180px;
            transform: rotate(100deg);

        }       
        
    }
    
    @media only screen and (min-width: 1280px) {

        .overKLEURENimg1{
            padding:0;
            margin: -125px;
            z-index:-1;
            transform: rotate(100deg);

        }

    } 
      

    @media only screen and (min-width: 1850px) {

        .overKLEURENimg1{
            padding:0;
            margin: 0;
            transform: rotate(100deg);

        }

    }    

</style>


<template>
    <div class="overKLEURENtxt">
        <div class="txt">
            <p class="title">KLEUREN</p>

            <p class='p1' lang="nl">Bij RISO wordt elke kleur apart gedrukt, zogenaamde spot kleuren. De inkt is niet 100% dekkend, elke kleur heeft een eigen opaciteit. Als de kleuren overlappen onstaan er door transparantie of optische illusie nieuwe kleuren op papier. </p>
            <p class='p1' lang="nl">Het is mogelijk om in de RISO kleuren te zoeken naar een combinatie die de klassieke CMYK print techniek nabootst. Maar het is veel leuker om dit los te laten en meer spannende combinaties te zoeken!</p>
                
            <p class='p1' lang="nl">Er zijn meer dan 50 <a href="https://www.stencil.wiki/colors" target="_blank" rel="noopener noreferrer"> RISO kleuren</a>. Bij RISOOM hebben we momenteel 9 kleuren ter beschikking: black, fluo orange, fluo pink, bright red, purple, blue, teal, green en yellow. De HEX en LAB waarden kunnen gebruikt worden om de RISO kleuren na te bootsen, om een idee te geven van het eindresultaat. De kleuren op papier zijn namelijk onmogelijk waarheidsgetrouw op het scherm te tonen, vooral de fluo kleuren zijn moeilijk.</p> 
        </div>

        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'overKLEURENtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overKLEURENtxt {
        background-color:#ff747765;
    }

    .overKLEURENtxt .txt {

    }    

    .overKLEURENtxt p.title{
        position: absolute;
        top: -50px;
        right: -50px;    
    }    


    @media only screen and (max-width: 1279px) {
        .overKLEURENtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="examplesTitle">
        <img src="images/examplesTitle.png"/>
    </div>    
</template>

<script>
export default {
        name: 'examplesTitle',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .examplesTitle{
        margin-bottom: -50px;
        margin-left:2.5vw;
 
    }

    @media only screen and (min-width: 640px) {
 
    }
      

    @media only screen and (min-width: 1024px) {


    }  

    @media only screen and (min-width: 1280px) {  

    }  

    @media only screen and (min-width: 1536px) {


    }  

</style>


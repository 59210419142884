<template>
    <div class="CNTCTtxt">
        <div class="txt">
            <p class="title">CONTACT</p>
            <p class='p1' lang="nl">Je kan ons als volgt bereiken:
                <ul>
                    <li><div><p><a href="mailto:info.risoom@gmail.com<">info.risoom@gmail.com</a></p></div></li>
                    <li><div><p>chat via <a href="https://www.instagram.com/risoom.studio/" target="_blank" rel="noopener noreferrer">@risoom_studio</a></p></div></li>
                    <li><div><p>0032479653640</p></div></li>
                </ul>
                We bevinden ons momenteel bij <a href="https://www.gumm-cohousing.be" target="_blank" rel="noopener noreferrer">Gumm cohousing</a> gelegen op Kerkhoflei 49a in 2800 Mechelen.                
            </p>
        </div>

        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>
    </div>   

     
</template>

<script>
    export default {
        name: 'CNTCTtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .CNTCTtxt {
        background-color:#ff747765;
    }

    .CNTCTtxt .txt {

    }    

    .CNTCTtxt p.title{
        position: absolute;
        top: -50px;
        right: 50px;    
    }    


    @media only screen and (max-width: 1279px) {
        .CNTCTtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="letsWRKSHPtxt">
        <div class="txt">
            <p class="title">WORKSHOP</p>
            <p class='p1' lang="nl">Bij RISOOM kan je terecht voor een RISO workshop. We gaan zowel technisch als praktisch dieper in de wereld van RISO printen. Je leert je bestanden voorbereiden om te printen en er is wat meer ruimte om te experimenteren. We zoeken samen wat RISO kan doen met jouw bestanden gesteund door onze ervaring en jouw creativiteit. Je gaat uiteindelijk naar huis met minimaal 10 afdrukken van 1 RISO print. Een workshop kost minimaal 185 euro voor 2 personen en duurt een halve dag [4 uur]. Per extra persoon of halve dag is het een extra 90 euro.
            </p>
        </div>   
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'letsWRKSHPtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .letsWRKSHPtxt {
        background-color:#ff747765;
    }

    .letsWRKSHPtxt .txt {

    }    

    .letsWRKSHPtxt p.title{
        position: absolute;
        top: -50px;
        right: 50px;    
    }    


    @media only screen and (max-width: 1279px) {
        .letsWRKSHPtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="examplesIMG1">

            <div class="XMPLs">

                <img src="images/examples/HMBRG_TL-FLRNG-YLLW001.png"/>
                <div class="caption">
                    <span><i>FLAKTURM IV</i> by <a href="https://www.takemesomewherenice.be" target="_blank" rel="noopener noreferrer">TMSN</a> in <font color="#007679">&#x25B2;</font><font color="#ff7477">&#x25B2;</font><font color="#ffe800">&#x25B2;</font> on STEINBEIS Nr.1 80gsm</span>
                </div>
            

            </div>



    </div>    
</template>

<script>
    export default {
        name: 'examplesIMG1',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .examplesIMG1 {


    }

    .examplesIMG1 .txt {

    }    
    
    .XMPLs {

        display: flex;
      
    }

    .XMPLs img{


    }
    
    .XMPLs .caption {
        bottom:10px;
        font-family: "lores-12", sans-serif;
        font-weight: 400;
        font-style: normal;
        text-align: right;   
        font-size: calc(14px + 0.4vw);
        writing-mode: vertical-rl;

    }
    span{
        background-color: #fffdee;
    }  
   


      

</style>


<template>
    <div class="overSCREENStxt">
        <div class="txt">
            <p class="title">SCREENS</p>
            <p class='p1' lang="nl">A RISO printer is a printing device that pierces the design onto a screen of washi paper, a so-called master. The ink is then pressed via a cylindric drum through the screen onto the paper. There are 3 ways to pierce these little holes in the screen:
                <ul>
                    <li><div><p>Frequency modulation or grain touch. The holes are all equally large and are placed in a random pattern that follows the content of the design to be printed. More holes are pierced for darker objects. This allows to maximise detail, but it gives a strange effect for lighter shades [&lt; 10%].</p></div></li>
                    <li><div><p>Amplitude modulation or raster screen. The holes are pierced in a fixed pattern but the size is different. Larger sized holes are pierced for darker objects. The fixed pattern can be controlled based on the LPI or lines per inch, which varies between 38 and 200. Higher LPIs give more details but result in less differentiation between shades. All in all, the image will be darker, lighter shades can not be shown.</p></div></li>
                    <li><div><p>Lastly, software can be used to translate an image into a bitmap with black and white pixels. You can simply use <i>halftone screen</i>. In Photoshop to achieve the same effect as what the printing device would internally do. <a href="https://stochaster.org/stochaster.html" target="_blank" rel="noopener noreferrer">Stochaster</a> has even more options, allowing to combine frequency and amplitude modulation. We offer this option in the <i>print and prepress</i>  or <i>print together</i> modules.</p></div></li>
                </ul>
             </p>
             <p class='p1' lang="nl">The accompanying figure is a close-up of 35% grayscale printed in different screens, from left to right: 75LPI, 38LPI, 200LPI, grain touch, 105LPI and on top the stochastic screen.</p>             
        </div>

        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'overSCREENStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overSCREENStxt {
        background-color:#ff747765;
    }

    .overSCREENStxt .txt {

    }    

    .overSCREENStxt p.title{
        position: absolute;
        top: -55px;
            left: 50px;     
    }    

    td, th {
        border: 1px solid #262626;
        text-align: left;
        padding-right: 10px;
        padding-left: 5px;
        font-weight: inherit;
    }    

    table {
        margin-left: 20px;
        margin-right: 40px;
        margin-bottom:20px;
        font-size: calc(15px + 0.2vw);
        border: 1px solid #262626;
        border-collapse: collapse; 
    }


    @media only screen and (max-width: 1279px) {
        .overSCREENStxt p.title{
            position: absolute;
            top: -55px;
            left: 50px; 
        }   

    } 
    

    @media only screen and (max-width: 680px) {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #262626; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #262626; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 2px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

    td:nth-of-type(1):before { content: "PAPIER"; }
	td:nth-of-type(2):before { content: "GSM"; }
	td:nth-of-type(3):before { content: "RECYCLED?"; }
	td:nth-of-type(4):before { content: "KLEUR"; }
	td:nth-of-type(5):before { content: "PRIJS"; }
	
}
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


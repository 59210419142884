<template>
    <div class="overSCREENStxt">
        <div class="txt">
            <img src="images/screens35prcnt.png" style="box-shadow: 10px 10px #00838a85;" />
        </div>




    </div>    
</template>

<script>
    export default {
        name: 'overSCREENStxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overSCREENStxt {

    }

    .overSCREENStxt .txt {

    }    

    .overSCREENStxt p.title{
        position: absolute;
        top: -55px;
            left: -35px;     
    }    

    td, th {
        border: 1px solid #262626;
        text-align: left;
        padding-right: 10px;
        padding-left: 5px;
        font-weight: inherit;
    }    

    table {
        margin-left: 20px;
        margin-right: 40px;
        margin-bottom:20px;
        font-size: calc(15px + 0.2vw);
        border: 1px solid #262626;
        border-collapse: collapse; 
    }


    @media only screen and (max-width: 1279px) {
        .overSCREENStxt p.title{
            position: absolute;
            top: -55px;
            left: -35px; 
        }   

    } 
    

    @media only screen and (max-width: 680px) {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	
	tr { border: 1px solid #262626; }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #262626; 
		position: relative;
		padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 2px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}

    td:nth-of-type(1):before { content: "PAPIER"; }
	td:nth-of-type(2):before { content: "GSM"; }
	td:nth-of-type(3):before { content: "RECYCLED?"; }
	td:nth-of-type(4):before { content: "KLEUR"; }
	td:nth-of-type(5):before { content: "PRIJS"; }
	
}
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="overIMPERFECTIESimg3">
        <img src="images/hand.png" />
    </div>
</template>

<script>
    export default {    
            name: 'overIMPERFECTIESimg3.vue',
            methods: {
                lFilter: function(e) {
                    this.$parent.mfilter(e);         
                    window.scrollTo(0,0); 
                }

            }
        }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>    

     @media only screen and (min-width: 0px) {
        
        .overIMPERFECTIESimg3{
            margin-left: 80px;
            margin-right: -80px;
            margin-bottom: -80px;
            margin-top: -80px;

        }       
        
    }
    
    @media only screen and (min-width: 1280px) {

        .overIMPERFECTIESimg3{
            margin-left: 50px;
            margin-right: -150px;
            margin-bottom: 0px;
            margin-top: -50px;


        }

    } 
      

    @media only screen and (min-width: 1850px) {

        .overIMPERFECTIESimg3{
            margin-left: 50px;
            margin-right: -150px;
            margin-bottom: 0px;
            margin-top: -50px;


        }

    }    

</style>


<template>
    <div class="overKLEURENtxt">
        <div class="txt">
            <p class="title">COLORS</p>

            <p class='p1' lang="nl">In RISO, every colour is printed separately, so=called ‘spot colours’. The ink is not 100% opaque, this differs for every colour. When colours overlap, the transparency or optical illusion creates new colours on paper. </p>
            <p class='p1' lang="nl">The available RISO colours allow to search for colour combinations that match the classical CMYK printing technique. But it’s even more fun to let it go and search for more exciting combinations!</p>
                
            <p class='p1' lang="nl">There are more than 50 <a href="https://www.stencil.wiki/colors" target="_blank" rel="noopener noreferrer">RISO colors</a>. At RISOOM, we’re currently offering 9 colours: black, fluo orange, fluo pink, bright red, purple, blue, teal, green and yellow. The HEX and LAB values can be used to recreate RISO colours, giving an idea of the end result to expect. The colours on paper, especially the fluo colours, are impossible to show truthfully on screen.</p> 
        </div>        
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'overKLEURENtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .overKLEURENtxt {
        background-color:#ff747765;
    }

    .overKLEURENtxt .txt {

    }    

    .overKLEURENtxt p.title{
        position: absolute;
        top: -50px;
        right: -50px;    
    }    


    @media only screen and (max-width: 1279px) {
        .overKLEURENtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>


<template>
    <div class="letsWRKSHPtxt">
        <div class="txt">
            <p class="title">WORKSHOP</p>
            <p class='p1' lang="nl">RISOOM also offers RISO printing workshops. We will take you deeper into the world of RISO printing, technically as well as practically. You learn how to translate files for RISO printing, and we have room for more experimentation. We find out together what RISO can do with your files, supported by our experience and your creativity. You’ll go back home with at least 10 copies of 1 RISO print. A workshop costs minimum 185EUR for 2 persons and takes about half a day [4h]. Each additional person of half a day adds 90EUR.
            </p>
        </div>        
        <div class="CLRbg"> </div>
        <div class="CLRyllw"> </div>



    </div>    
</template>

<script>
    export default {
        name: 'letsWRKSHPtxt',
        methods: {
            lFilter: function(e) {
                this.$parent.mfilter(e);
                window.scrollTo(0,0); 
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .letsWRKSHPtxt {
        background-color:#ff747765;
    }

    .letsWRKSHPtxt .txt {

    }    

    .letsWRKSHPtxt p.title{
        position: absolute;
        top: -50px;
        right: 50px;    
    }    


    @media only screen and (max-width: 1279px) {
        .letsWRKSHPtxt p.title{
            position: absolute;
            top: -55px;
            right: -40px;
        }   

    } 
    

    @media only screen and (min-width: 1280px) {


    } 
      

    @media only screen and (min-width: 1850px) {


    }  
</style>

